import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  // Dependency Injection Starts

  private control = inject(ApiService);

  // Dependency Injection Ends

  private stack: number[] = [];

  private handleError(err: HttpErrorResponse) {
    this.control.spin(false);
    if (err.status === 401 || err.status === 403) {
      this.stack = [];
      this.control.logout();
    }
    return throwError(() => {
      this.stack = [];
      return err;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.control.spin(true);

    this.stack.push(1);

    //GET TOKEN
    const authToken = localStorage.getItem('intelekt-token');

    //SET TOKEN
    const authRequest = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken),
    });

    return next
      .handle(authRequest)
      .pipe(
        tap((event: any) => {
          if (event instanceof HttpResponse) {
            this.stack.pop();

            if (!this.stack.length) {
              this.control.spin(false);
            }
          }
        }),
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }
}
