import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

interface ResApi {
  status: boolean;
  path: string;
  statusCode: number;
  result: {
    message: string;
    data: any;
  };
}

type obj = {
  [key: string]: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.baseURL;

  private spinner = new Subject<boolean>();

  private params: any = {};

  capitalize = (s: string) =>
    s
      .toLowerCase()
      .split(' ')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join(' ');

  constructor(
    private http: HttpClient,
    private notification: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.params = this.queryParams();
        this.params.path = val.url;
      }
    });
  }
  getSpinner() {
    return this.spinner.asObservable();
  }

  public get getParams() {
    return this.params;
  }

  spin(state: boolean) {
    this.spinner.next(state);
  }

  queryParams() {
    const keys = this.route.snapshot.queryParamMap.keys;
    const obj: obj = {};

    keys.forEach((e: string) => {
      obj[e] = this.route.snapshot.queryParamMap.get(e);
    });

    return obj;
  }

  openNotification(message: string, type = 'success'): void {
    this.notification.create(type, this.capitalize(message));
  }

  openNotificationNoMod(message: string, type = 'success'): void {
    this.notification.create(type, message);
  }

  public get loggedIn(): boolean {
    return localStorage.getItem('intelekt-token') !== null;
  }

  public get userData(): any {
    return JSON.parse(localStorage.getItem('intelekt-user') || '');
  }

  logout() {
    localStorage.removeItem('intelekt-token');
    localStorage.removeItem('intelekt-user');
    this.router.navigate(['/login']);
  }

  // http methods starts from here

  // login function
  login(payload: object) {
    return this.http.post<ResApi>(`${this.baseUrl}/users/login`, payload);
  }

  requestLogs(payload: object) {
    return this.http.get<ResApi>(`${this.baseUrl}/external-api`, {
      params: { ...payload },
    });
  }

  count(payload: object) {
    return this.http.get<ResApi>(`${this.baseUrl}/external-api/count`, {
      params: { ...payload },
    });
  }

  billingCount(payload: object) {
    return this.http.get<ResApi>(`${this.baseUrl}/external-api/billing-count`, {
      params: { ...payload },
    });
  }
}
