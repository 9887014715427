<div class="login-background div-center">
  <div class="flex">
    <img src="assets/imgs/login.svg" alt="" />
  </div>
  <div class="login-wrapper">
    <img src="assets/imgs/big-circle.png" class="login-big-circle" alt="" />
    <img src="assets/imgs/small-circle.png" class="login-small-circle" alt="" />
    <h1>Welcome !</h1>
    <p>Sign in to your account to continue</p>
    <div class="form-container">
      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" class="login-form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your email!">
            <p>Email</p>
            <nz-input-group>
              <input class="login-input" type="text" nz-input formControlName="email" placeholder="Enter Your Email" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <p>Password</p>
            <nz-input-group class="input-password" [nzSuffix]="suffixTemplate0">
              <input
                class="login-input"
                [type]="passwordVisible ? 'text' : 'password'"
                type="password"
                nz-input
                formControlName="password"
                placeholder="Password"
              />
            </nz-input-group>
            <ng-template #suffixTemplate0>
              <i
                nz-icon
                class="text-base"
                [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <br />
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" [nzLoading]="isLoginIn">
          {{ isLoginIn ? '' : 'Sign In' }}
        </button>
      </form>
    </div>
  </div>
</div>
