import { Injectable, OnDestroy, inject } from '@angular/core';

import { Subject } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export abstract class GlobalUnsubscribe implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  protected control = inject(ApiService);

  fakeArray(length: number): Array<number> {
    if (length >= 0) {
      return [...Array(length).keys()];
    }
    return [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
