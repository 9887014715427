import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, takeUntil, throwError } from 'rxjs';
import { GlobalUnsubscribe } from 'src/app/class/global-unsubscribe.class';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends GlobalUnsubscribe implements OnInit {
  // Dependency Injection Starts

  private fb = inject(FormBuilder);
  private router = inject(Router);

  // Dependency Injection Ends

  validateForm!: FormGroup;
  isLoginIn = false;
  passwordVisible = false;

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.isLoginIn = true;
      const { email, password } = this.validateForm.value;
      this.control
        .login({ email: email.trim(), password: password.trim() })
        .pipe(
          catchError((err) => {
            this.isLoginIn = false;
            this.control.openNotification(err.error.message, 'error');
            return throwError(() => {
              return err;
            });
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((res) => {
          this.isLoginIn = false;
          if (res.status) {
            const { data } = res.result;
            localStorage.setItem('intelekt-token', data.token);
            data.user.name = this.control.capitalize(`${data.user.firstName} ${data.user.lastName}`);
            localStorage.setItem('intelekt-user', JSON.stringify(data.user));

            this.router.navigate(['/auth/dashboard']);
            this.control.openNotification(res.result.message);
          } else {
            this.control.openNotification(res.result.message, 'error');
          }
        });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
