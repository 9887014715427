import { ErrorHandler, Injectable, NgZone, inject } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  // Dependency Injection Starts

  private control = inject(ApiService);
  private zone = inject(NgZone);

  // Dependency Injection Ends

  handleError(error: any) {
    this.zone.run(() => {
      // this.control.openNotificationNoMod(
      //   error?.message || 'Error was detected! We are already working on it!',
      //   'error',
      // );
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
}
