import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class CheckLoginGuard implements CanActivate {
  // Dependency Injection Starts

  private control = inject(ApiService);
  private router = inject(Router);

  // Dependency Injection Ends

  canActivate(): boolean {
    if (this.control.loggedIn === false) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
